<template>
  <div class="mt-0-px pb-0">
    <!-- <notifications></notifications> -->
    <div id="startseite">
      <div class="logo">
        <img src="https://arvea-dev.batix.ch/pic/17CB79561E9_181x63.png"/>
      </div>

      <div class="sidebar">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="textblock">
        <h1>
          ARVEA Universe <br/>
          Calculator
        </h1>
        <p>
          Arvea Investments AG is a Swiss fintech investment advisory firm using
          <br/>
          proprietary non-linear models. Arvea Investments AG was founded to
          deliver <br/>
          innovative and unique financial investment products to meet the needs
          of <br/>
          investors in this changing market environment.
        </p>
        <!-- 
        <div class="row icons" v-if="angemeldeterMitarbeiter">
          <div class="col-3">
            <div class="calculate"></div>
          </div>
          <div class="col-3">
            <div class="visualize"></div>
          </div>
          <div class="col-3">
            <div class="export"></div>
          </div>
        </div>
        -->
      </div>
      <!-- 
      <div class="user" v-if="angemeldeterMitarbeiter">
        <i class="fal fa-user-circle"></i>
        <p>{{ angemeldeterMitarbeiter.mitarbeiter.vorname }}</p>
      </div>
      <div class="row navbar" v-if="angemeldeterMitarbeiter">
        <div class="col-3">
          <p>Calculate</p>
          <span></span>
        </div>
        <div class="col-3">
          <p>Visualize</p>
          <span></span>
        </div>
        <div class="col-3">
          <p>Export</p>
          <span></span>
        </div>
      </div>
      -->
      <!-- <div class="loginbar" v-if="!angemeldeterMitarbeiter"> -->
      <div v-if="!isNewPasswordRequired" class="loginbar">
        <h1>LOGIN</h1>
        <p>Please sign in to continue</p>
        <div class="usernameContainer">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="339.336"
              height="65.977"
              viewBox="0 0 339.336 65.977"
          >
            <g
                id="Group_120"
                data-name="Group 120"
                transform="translate(17533 1175.256)"
            >
              <text
                  id="Username"
                  transform="translate(-17511.117 -1158.256)"
                  :fill="inputColor"
                  font-size="18"
                  font-family="Montserrat-Medium, Montserrat"
                  font-weight="500"
                  letter-spacing="0.025em"
              >
                <tspan x="0" y="0">USERNAME</tspan>
              </text>
              <path
                  id="Path_3951"
                  data-name="Path 3951"
                  d="M145.536,1H331.107c3.993,0,7.229,3.6,7.229,8.044V45.688c0,4.443-3.236,8.044-7.229,8.044H8.229C4.236,53.732,1,50.131,1,45.688V9.044C1,4.6,4.236,1,8.229,1h7.837"
                  transform="translate(-17533 -1164.011)"
                  fill="none"
                  :stroke="inputColor"
                  stroke-width="2"
              />
            </g>
          </svg>
          <input
              id="username"
              type="text"
              name="username"
              aria-describedby="username"
              autocapitalize="off"
              spellcheck="false"
              autocorrect="off"
              v-model="username"
          />
        </div>
        <div class="passwordContainer">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="339.336"
              height="65.977"
              viewBox="0 0 339.336 65.977"
          >
            <g
                id="Group_121"
                data-name="Group 121"
                transform="translate(17533 1066.15)"
            >
              <path
                  id="password_field"
                  d="M147.624,1H331.107c3.993,0,7.229,3.6,7.229,8.044V45.688c0,4.443-3.236,8.044-7.229,8.044H8.229C4.236,53.732,1,50.131,1,45.688V9.044C1,4.6,4.236,1,8.229,1h7.837"
                  transform="translate(-17533 -1054.906)"
                  fill="none"
                  :stroke="inputColor"
                  stroke-width="2"
              />
              <text
                  id="PASSWORD"
                  transform="translate(-17511.117 -1049.15)"
                  :fill="inputColor"
                  font-size="18"
                  font-family="Montserrat-Medium, Montserrat"
                  font-weight="500"
                  letter-spacing="0.025em"
              >
                <tspan x="0" y="0">PASSWORD</tspan>
              </text>
            </g>
          </svg>
          <input
              id="password"
              type="password"
              name="password"
              aria-describedby="password"
              autocomplete="off"
              autocapitalize="off"
              spellcheck="false"
              autocorrect="off"
              v-model="password"
          />
        </div>
        <div @click="authenticateUser">SIGN IN</div>
      </div>

      <!-- New Password Form -->
      <div v-if="isNewPasswordRequired" class="loginbar">
        <h1>SET NEW PASSWORD</h1>
        <p>You need to set a new password to continue</p>
        <div class="new-passwordContainer">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="339.336"
              height="65.977"
              viewBox="0 0 339.336 65.977"
          >
            <g
                id="Group_122"
                data-name="Group 122"
                transform="translate(17533 960.15)"
            >
              <path
                  id="new_password_field"
                  d="M195.624,1H331.107c3.993,0,7.229,3.6,7.229,8.044V45.688c0,4.443-3.236,8.044-7.229,8.044H8.229C4.236,53.732,1,50.131,1,45.688V9.044C1,4.6,4.236,1,8.229,1h7.837"
                  transform="translate(-17533 -949.906)"
                  fill="none"
                  :stroke="inputColor"
                  stroke-width="2"
              />
              <text
                  id="NEW_PASSWORD"
                  transform="translate(-17511.117 -943.906)"
                  :fill="inputColor"
                  font-size="18"
                  font-family="Montserrat-Medium, Montserrat"
                  font-weight="500"
                  letter-spacing="0.025em"
              >
                <tspan x="0" y="0">NEW PASSWORD</tspan>
              </text>
            </g>
          </svg>
          <input
              id="newPassword"
              type="password"
              name="newPassword"
              aria-describedby="newPassword"
              autocomplete="off"
              autocapitalize="off"
              spellcheck="false"
              autocorrect="off"
              v-model="newPassword"
          />
        </div>
        <div @click="submitNewPassword">SUBMIT NEW PASSWORD</div>
      </div>
    </div>
  </div>

  <!-- STARTSEITE ALT (CALCULATOR) -->
  <!--
  <div :key="key" class="mt-70-px">
    <navbar> </navbar> -->
  <!-- Start Login -->
  <!-- Ende Login -->
  <!--
    <notifications></notifications>
    <div v-if="angemeldeterMitarbeiter">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="tile-categorie-frame mt-5">
            <div class="tile-categorie">
              <div class="body">
                <vue-2-dropzone
                  id="dropzone"
                  ref="universeUpload"
                  class="mt-3"
                  :options="dropzoneOptions"
                  @vdropzone-removedfile="fileAdded"
                  @vdropzone-success="fileUploaded"
                  @vdropzone-error="uploadError"
                ></vue-2-dropzone>
              </div>
              <button
                class="btn btn-primary mt-2 mr-2"
                @click="universeBereinigen"
              >
                Upload
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div>
            <loading-overlay v-show="loading" :text="text"> </loading-overlay>
          </div>
        </div>

        <div class="row mt-5 justify-content-center">
          <equities-waehlen :listings="listings[0]"></equities-waehlen>
        </div>



         v button auskommentiert 
         <button 

            class="btn btn-success mt-2 mr-2 "
            @click="universeBerechnen"
            >            
            <em>Calculate</em>
          </button> 
      </div>
      
    </div>
    <alert-modal
      :id="id"
      :title="titleConfirmModal"
      :text="textConfirmModal"
      :selectedEqs="selectedEquitiesAlertModal"
      :notSelectedEqs="notSelectedEquitiesAlertModal"
    />
  </div>
  -->
</template>

<script>
import axios from "axios";
import store from "@/store";
import server from "../server";
import {CognitoUserPool, CognitoUser, AuthenticationDetails} from 'amazon-cognito-identity-js';
import api from "@/Api";


export default {
  name: "Login",
  mixins: [],
  store,
  data() {
    return {
      username: '',
      password: '',
      newPassword: '',
      cognitoUser: null,
      userAttributes: null,
      isNewPasswordRequired: false,
      inputColor: "#fff",
      poolData: {
        UserPoolId: 'eu-central-2_nLZXCM137', //'eu-central-2_5mdN5TSri',
        ClientId: '4if100um8m6t8coa12dtv9vifm', //'1du2iv8q1jbjn2rgpvbu31d9sc',
      }
    };
  },
  computed: {},
  methods: {
    //  async login() {
    login() {
      const params = new URLSearchParams();
      params.append("username", this.username);
      params.append("password", this.password);

      //     try {
      //    await axios.post(this.loginUrl, params)
      axios
          .post(this.loginUrl, params)
          .then(response => {
            console.log("response log in:" + response.data);
            if (!response.data) {
              console.log("wrong password");
              this.inputColor = "red";
              /*
              console.log("bin im response log in = null");
              let login = this.$router.resolve({ name: "Login" });
              window.location.href = login.href;*/
            } else {
              console.log("correct password");
              this.inputColor = "green";
              let startseite = this.$router.resolve({name: "StartseiteArvea"});
              window.location.href = startseite.href;
            }
          })
          .catch(error => {
            console.log("im catch vom axios post  passwort " + error.response);
            let login = this.$router.resolve({name: "Login"});
            window.location.href = login.href;
          });

      /*    } catch (error) {
        console.log(error) 
         console.log("im catch vom try") 
         let login = this.$router.resolve({name:'Login' })
        window.location.href = login.href;  
      }
*/
    },

    authenticateUser() {
      const userPool = new CognitoUserPool(this.poolData);

      if (!this.username || !this.password) return;

      const authenticationDetails = new AuthenticationDetails({
        Username: this.username,
        Password: this.password,
      });

      const userData = {
        Username: this.username,
        Pool: userPool,
      };

      const cognitoUser = new CognitoUser(userData);

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          this.sendIdTokenToBackend(result.getIdToken().getJwtToken(), result.getAccessToken().getJwtToken())
              .then(redirectUrl => {
                if (redirectUrl) {
                  let startseite = this.$router.resolve({name: redirectUrl});
                  window.location.href = startseite.href;
                }
              })
              .catch(err => {
                console.error('Error retrieving redirect URL:', err);
              });
        },
        onFailure: (err) => {
          console.error('Authentication failed:', err);
          this.inputColor = "red";
        },
        newPasswordRequired: (userAttributes) => {
          this.cognitoUser = cognitoUser;
          this.userAttributes = userAttributes;
          this.isNewPasswordRequired = true;
        },
      });
    },
    submitNewPassword() {
      if (this.cognitoUser && this.newPassword) {
        this.cognitoUser.completeNewPasswordChallenge(this.newPassword, {}, {
          onSuccess: () => {
            this.isNewPasswordRequired = false;
            this.authenticateUser();
          },
          onFailure: (err) => {
            console.error('Failed to change password:', err);
          },
        });
      }
    },

    sendIdTokenToBackend(idToken, accessToken) {
      const params = new URLSearchParams();
      params.append("idToken", idToken);
      params.append("accessToken", accessToken)

      return api.post("/get-redirect-url", params)
          .then(response => {
            if (response.status !== 200) {
              throw new Error('Network response was not ok');
            }
            return response.data;
          })
          .then(data => data.redirectUrl)
          .catch(err => {
            console.error('Failed to send id token to backend:', err);
            throw err;
          });
    },

  }
};
</script>

<style lang="scss">
body {
  background-image: url("https://arvea-dev.batix.ch/pic/17CB7957C9D_1920x1080.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  //background-position: center center;
}

#startseite .logo {
  position: relative;
  top: 0;
  width: 13vw;
  height: 17vh;
  //margin-top: 0px;
  display: inline-block;
  overflow: hidden;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.5);
  min-width: 150px;
  min-height: 100px;
}

#startseite .logo img {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  width: 75%;
  //min-height: 50%;
  transform: translate(-50%, -50%);
}

#startseite .sidebar {
  height: 100vh;
  width: 13vw;
  border-right: 1px solid white;
  position: absolute;
  top: 0;
  min-width: 150px;
  //display: flex;
  //justify-content: right;
  //align-items: center;
}

#startseite .sidebar div {
  background-color: white;
  position: relative;
  top: 50%;
  left: 82%;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 10px;
  opacity: 0.5;
}

#startseite .sidebar div:nth-child(1) {
  top: 50%;
}

#startseite .sidebar div:nth-child(2) {
  width: 18px;
  height: 18px;
  top: 50%;
  left: 81.5%;
  opacity: 1;
}

#startseite .sidebar div:nth-child(3) {
  top: 50%;
}

#startseite .textblock {
  position: absolute;
  left: 20%;
  top: 30%;
  color: white;
  width: 770px;
}

#startseite .textblock h1 {
  font-size: 75px;
  font-weight: bold;
}

#startseite .textblock p {
  font-weight: 500;
  font-size: 1rem;
}

#startseite .user {
  color: white;
  position: absolute;
  top: 5%;
  right: 0;
  overflow: hidden;
  width: 300px;
}

#startseite .user svg {
  float: left;
  margin: 10px;
  font-size: 30px;
  //margin: 2px;
}

#startseite .user p {
  margin: 10px;
  font-size: 20px;
}

#startseite .icons div {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin: 50px 100px 0px 0px;
  cursor: pointer;
}

#startseite .icons div:last-child {
  margin-right: 0px;
}

#startseite .calculate {
  background-color: #213056;
  margin-left: 0px;
  margin-right: 50px;
}

#startseite .visualize {
  background-color: #8f98ae;
}

#startseite .export {
  background-color: #c5cbdc;
  margin-right: 0px;
}

#startseite .navbar {
  width: 435px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 5%;
  left: 20%;
  border-radius: 35px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#startseite .navbar > div {
  margin-right: 30px;
  padding-top: 7px;
  cursor: pointer;
}

#startseite .navbar > div:last-child {
  margin-right: 0px;
}

#startseite .navbar > div > p {
  margin-top: 5px;
  margin-bottom: 0.7rem;
}

#startseite .navbar > div:first-child > p {
  padding-left: 3px;
}

#startseite .navbar > div:nth-child(2) > p {
  padding-left: 4px;
}

#startseite .navbar > div:last-child > p {
  padding-left: 14px;
}

#startseite .navbar > div > span {
  height: 5px;
  width: 85px;
  display: block;
  border-radius: 5px;
}

#startseite .navbar > div:first-child > span {
  background-color: #213056;
}

#startseite .navbar > div:nth-child(2) > span {
  background-color: #8f98ae;
}

#startseite .navbar > div:last-child > span {
  background-color: #c5cbdc;
}

#startseite .loginbar {
  position: absolute;
  height: 100vh;
  width: 27vw;
  min-width: 460px;
  background-color: rgba(0, 0, 0, 0.5);
  right: 0;
  top: 0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}

#startseite .loginbar h1 {
  margin-bottom: 0;
  margin-top: -70px;
}

#startseite .loginbar p {
  margin-bottom: 35px;
}

#startseite .loginbar > div > input {
  border: 2px solid white;
  border: none;
  margin: 5px;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  //background: none;
  background: rgba(255, 255, 255, 0);
  border-radius: 10px;
  padding: 12px;
  width: 330px;
  font-size: 15px;
  color: white;
  position: relative;
  z-index: 2;
}

#startseite .loginbar > div > input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: white;
}

#startseite .loginbar input:focus,
textarea:focus {
  outline: none;
  //box-shadow: 0 0 5px white;
  //-moz-box-shadow: 1px 1px 10px white;
  //-webkit-box-shadow: 1px 1px 10px white;
}

#startseite #password {
  margin-top: -10px;
}

#startseite label {
  position: relative;
  font-weight: 500;
  bottom: 66px;
  right: 108px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#startseite .loginbar div:last-child {
  width: 350px;
  background-color: #213056;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  font-weight: 600;
  margin-top: 65px;
  cursor: pointer;
}

#startseite .loginbar > div {
  position: relative;
  width: 340px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#startseite .loginbar > div > svg {
  position: absolute;
  z-index: 1;
  top: -8px;
}

#startseite .loginbar > div > input {
  //border: 2px solid white;
  border: none;
  margin: 5px;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  //background: green;
  border-radius: 10px;
  padding: 12px;
  width: 330px;
  font-size: 15px;
  color: white;

  position: relative;
  z-index: 2;
  width: 93%;
  padding-top: 15px;
}

#startseite .passwordContainer {
  margin-top: 25px;
}

#startseite .passwordContainer > input {
  top: 15px;
}

#startseite .new-passwordContainer {
  margin-top: 25px;
}

/*
COLORS:
dark blue: #213056
medium blue: #8f98ae
light blue: #c5cbdc
*/
</style>
